import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';
import vceApi from '../vceApi';
import { Button } from 'suomifi-ui-components';
import { useTranslation } from 'react-i18next';
import { SimplifiedPresentationPayload } from '../api/vce-api/vce-api';

type Props = {
  credentialType: string,
  claimsCSV: string,
  client_id_scheme?: "did"|"redirect_uri",
  pathType?: "sd-jwt"|"jwt",
  callback: (data: SimplifiedPresentationPayload)=>void,
  title?: string
}


function RequestCredential({ callback, credentialType, claimsCSV, client_id_scheme, pathType, title }: Props) {
  // qrcode related stuff
  const [state, setState] = useState<string>("");
  const [counter, setCounter] = useState<number>(-1);
  const [redirectUri, setRedirectUri] = useState<string | undefined>(undefined);
  const [showRenew, setShowRenew] = useState<boolean>(false);
  const { t } = useTranslation();

  const renewQrCode = () => {
    setCounter(-1);
    setShowRenew(false);
  }

  useEffect(() => {
    return () => { // unmount
      //clearTimeout(timer);
      //setCounter(-1);
    };
  }, []);

  const verifierName = "verifier";
  useEffect(() => {
    if (state) { // state is changed every 3 minutes
      // Create PID presentation request
      vceApi.verifiers.createPresentationRequestSimple(verifierName, credentialType, { claimsCSV: claimsCSV, state: state, /*clientId: `did:web:test.minisuomi.fi:api:verifiers:${verifierName}`*/ pathType: pathType, client_id_scheme: client_id_scheme }).then(x => {
        console.log(`redirectUri: ${x.data.redirectUri}`); // todo pitäisiköhän ohjata suomifi tunnistautumiseen? vai mikä tämä oli?
        //x.data.redirectUri = "https://"
        vceApi.verifiers.initiateTransaction(verifierName, x.data, { client_id_scheme: client_id_scheme }).then(x => {
          console.log(x.data.responseUri);

          // Update url for qr code and start counter
          setRedirectUri(x.data.responseUri);
          setCounter(0);
  
        }).catch(x => console.log(x));
      }).catch(x => console.log(x));
    }
  }, [state, claimsCSV,credentialType, client_id_scheme, pathType]);

  useEffect(() => {
    if (counter === -1 && !state) {
      // Init state
      const newState = window.crypto.randomUUID().replace(/-/g,"");
      setState(newState);
    } else if (counter >= 0) {
      if (counter >= 10) { 
        console.log("Counter >= 10");
        setState("");
        setRedirectUri("");
        setShowRenew(true);
      }
      else {
        // Polling function tries to poll about 30 seconds, credential presentation is valid about 3 minutes
        vceApi.verifiers.poll(verifierName, state, { credentials: "omit"}).then(x => {
          const data = x.data;
          console.log(data);  
          if (data !== null) {
            callback(data as SimplifiedPresentationPayload);
          }
          else {
            console.log("not verified yet");
            setCounter(counter+1);
          }
        }).catch((e: Error) => {
          console.log(e);
          if (e.message === "state not found") {
            // state not found, do not continue polling
          }
          else {
            console.log("unkonwn polling error? trying again...");
            setCounter(counter+1);
          }
          
        });

      }
    }
  }, [counter, callback, state])

  return (<>
    {redirectUri &&
      <div style={{ height: "auto", margin: "0 0", maxWidth: 192, width: "100%" }} title={`${credentialType}${pathType ? ` (${pathType})` : ''}: ${claimsCSV}`}>
        <a href={redirectUri}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={redirectUri}
            viewBox={`0 0 256 256`}
          />
        </a>
        <p style={{textAlign: "center", margin: 0}}>{title||credentialType}</p>
      </div>
      }
      { showRenew && <>

        <Button onClick={renewQrCode}>{t("renew")}</Button>
        </>
      }
    </>
  )
}

export default RequestCredential

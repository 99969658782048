import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "en",
        resources: {
            en: {
                translation: {
                    appName: "Mini-PRH",  
                    appDescription: "Patent and registration office (demo)",
                    companyView: {
                        title: "Company information",
                        info: "Here you can get LPID and EUCC credentials for Finnish company. Please select company and then later scan the QR-code with your company wallet.",
                        companySelection: "Select company",
                        companyPlaceHolder: "-- Select company --",
                        noCompanies: "You have not been listed as legal presentative to any company"
                    },
                    credentialNames: {
                        eucc: "Trade register extract (eucc)",
                        lpid: "LPID (lpid)",
                        xbrlje: "Financial statement information (xbrlje)",
                        agent3: "Ultimate beneficial owners (agent3)"
                    },
                    credentialsView: {
                        companyCode: "Company ID",
                        requestCredentialOffers: "Request credential offers",
                        sendToOrganizationWallet: "Send selected certificates to company wallet",
                        description: "Mini-PRH office can issue electronic certificates to digital wallets.",
                        formInfo: "Please give your company wallet address:",
                        success: "Certificates has been sent to the wallet",
                        title: "Certificates",
                        walletAddress: "Wallet address (domain or web-did)",
                    },
                    footer: {
                        ariaClose: "Close modal",
                        accessibilityStatement: "accessibility statement",
                        contactInformation: "contact information",
                        dataProtection: "data protection"
                    }, 
                    homeView: {
                        applyLPID: "Request LPID",
                        gotoCertificates: "Request certificates",
                        pageDescription: "This is demo site for the <1>Real time economy</1> project's experiments.",
                        pageInfo: "Log in to demonstrate certificate with wallets.",
                        welcome: "Welcome to Mini-PRH",
                    },
                    loading: "Loading...",
                    login: "Log in",                   
                    loginView: {
                        email: "e-mail",
                        password: "password",
                        title: "Identification",
                        failed: "Login failed",
                    },
                    logout: "Log out",
                    logoutView: {
                        title: "You have been logged out",
                        redirect: "Continue to frontpage",
                    },
                    
                    route: {
                        credentials: "Credentials",
                        eudtrc: "Residence certificate",
                        home: "Frontpage",
                        company: "Company Information"
                    },
                    requesting: "Requesting...",
                    sending: "Sending...",
                    ydUrl: "https://www.yrityksendigitalous.fi/en/",
                    
                }
            },
            fi: {
                translation: {
                    appName: "Mini-PRH",
                    appDescription: "Patentti- ja rekisterihallitus (demo)",
                    companyView: {
                        description: "Täältä voit saada suomalaisen yrityksen LPID ja EUCC kredentiaalit yrityslompakkoosi.",
                        companySelection: "Ole hyvä ja valitse yritys, jonka puolesta haluat asioida",
                        companyPlaceHolder: "-- Valitse yritys --",
                        noCompanies: "Sinulla ei ole oikeuksia toimia oikeushenkilöiden puolesta"
                    },
                    credentialNames: {
                        eucc: "Kaupparekisteriote (eucc)",
                        lpid: "LPID (lpid)",
                        xbrlje: "Tilinpäätöstiedot (xbrlje)",
                        agent3: "Edunsaajaluettelo (agent3)"
                    },
                    credentialsView: {
                        companyCode: "Y-tunnus",
                        requestCredentialOffers: "Pyydä todistukset",
                        sendToOrganizationWallet: "Lähetä valitut todistukset yrityksen lompakkoon",
                        description: "Mini-PRH voi myöntää todistuksia. Ole hyvä ja syötä lompakon tiedot saadaksesi todistukset lompakkoon.",
                        formInfo: "Anna yrityslompakon osoite:",
                        success: "Todistus lähetetty lompakkoon.",
                        title: "Todistuksien lataus yrityslompakkoon",
                        walletAddress: "Lompakon osoite (www-sivu tai web-did)",
                    },
                    footer: {
                        ariaClose: "Sulje modaali",
                        accessibilityStatement: "saavutettavuusseloste",
                        contactInformation: "yhteystiedot",
                        dataProtection: "tietosuoja"
                    },
                    homeView: {
                        applyLPID: "Hanki LPID",
                        gotoCertificates: "Pyydä todistuksia",
                        pageDescription: 'Tämä on demosivusto, joka on luotu <1>yrityksen digitalous</1>-hankkeen kokeiluja varten.',
                        pageInfo: "Kirjaudu sisään demotaksesi todistuksien myöntämistä ja lähettämistä lompakkojen avulla.",
                        pageSlogan: "Patentit ja rekisterit",
                        welcome: "Tervetuloa Mini-PRH:en"
                    },
                    loading: "Lataa...",
                    login: "Kirjaudu",
                    loginView: {
                        email: "sähköposti",
                        password: "salasana",
                        title: "Tunnistautuminen",
                        failed: "Kirjautuminen epäonnistui",
                    },
                    logout: "Kirjaudu ulos",
                    logoutView: {
                        title: "Olet uloskirjautunut",
                        redirect: "Siirry etusivulle",
                    },
                    CompanyView: {
                        title: "Oikeushenkilön identiteettitiedot (LPID)",
                        info: "Täällä voit hakea digilompakkoosi suomalaisen yrityksen tai yhteisön LPID-kredentiaalin skannaamalla alemman QR-koodin."
                    },
                    route: {
                        credentials: "Kredentiaalit",
                        eudtrc: "Kotipaikkatodistus",
                        home: "Etusivu",
                        company: "Yritystiedot",
                    },
                    requesting: "Lähettää pyyntöä...",
                    sending: "Lähettää...",
                    ydUrl: "https://www.yrityksendigitalous.fi/",
                }
            },
            sv: {
                translation: {
                    appName: "Mini-PRH",
                    appDescription: "Patent- och registreringsverket (demo)", 
                    credentialsView: {
                        sendToOrganizationWallet: "Skicka certifikat till företagets plånbok",
                        companyCode: "Företags ID",
                        description: "Mini-PRH kan utfärda certifikat.",
                        formInfo: "Ange företagets plånboksadress:",
                        success: "Bostadsbeviset har skickats till plånboken.",
                        title: "Ansök om certifikat",
                        walletAddress: "Plånboksadress (www-sida eller webb-gjort)",
                    },
                    footer: {
                        accessibilityStatement: "tillgänglighetsförklaring",
                        contactInformation: "kontaktinformation",
                        dataProtection: "dataskydd"
                    },    
                    homeView: {
                        gotoCertificates: "Begäran om certifikat",
                        pageDescription: "Detta är en demowebbplats för att testa experiment med <1>Realtidsekonomi</1>.",
                        pageInfo: "Logga in för att försöka söka efter ett certifikat i en företagsplånbok.",
                        welcome: "Välkommen till Mini-PRH (alternativ till PRV)",
                    },
                    loading: "",// "Laddar...",
                    login: "Logga in",
                    loginView: {
                        email: "e-post",
                        password: "lösenord",
                        title: "Identifiering",
                        failed: "Inloggningen misslyckades",
                    },
                    logout: "Logga ut",
                    logoutView: {
                        title: "Du är utloggad",
                        redirect: "Fortsätt till startsidan",
                    },
                    route: {
                        credentials: "Certifikat",
                        home: "Hemsida"
                    },
                    sending: "Sändning...",
                    ydUrl: "https://www.yrityksendigitalous.fi/sv/",
                }
            }
        }
    })